import React from 'react'
import { string, node } from 'prop-types'
import styled from 'styled-components'

import { white, black, grey } from '@/style-guide/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 614px;
  min-width: 402px;
  cursor: pointer;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  align-items: center;
  padding: 20px 25px;
  height: inherit;
  width: 100%;
  background-color: ${white};
`

const Header = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  height: inherit;
  width: 100%;
`

const Title = styled.div`
  font-size: 21px;
  line-height: 23px;
  color: ${black};
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const BriefDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${grey};
  margin-top: 20px;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ArticlePreview = ({ title, text, image, footer }) => (
  <Container>
    <Header image={image} />
    <Content>
      <Body>
        <Title>{title}</Title>
        <BriefDescription>{text}</BriefDescription>
      </Body>
      {footer}
    </Content>
  </Container>
)

ArticlePreview.propTypes = {
  title: string,
  text: string,
  image: string,
  footer: node
}

export default ArticlePreview
