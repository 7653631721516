import React from 'react'
import { object, string, number } from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

const Avatar = styled.img`
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`
const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const ArticleInfo = ({ style, styleText, avatar, author, minutesRead }) => (
  <Container style={style}>
    <Content>
      {avatar && <Avatar src={avatar} alt="Avatar" />}
      <Text style={styleText}>{author}</Text>
    </Content>
    <Text style={styleText}>{minutesRead || 'Less than 1'} min read</Text>
  </Container>
)

ArticleInfo.propTypes = {
  style: object,
  styleText: object,
  avatar: string,
  author: string,
  minutesRead: number
}

export default ArticleInfo
