import React from 'react'
import { number, string, bool } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white } from '@/style-guide/colors'

import bgImageLarger from '@/assets/images/bg_header-larger.svg'
import bgImageSmall from '@/assets/images/bg_header-small.svg'
import bgContent from '@/assets/images/bg-first-article.svg'

import search from '@/assets/images/search.svg'

import ArticleInfo from '@/components/ArticlePreview/ArticleInfo'

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 65px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url(${bgImageSmall});
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  background-color: #f7f7f7;

  ${media.greaterThan('medium')`
    height: 369px;
    background-image: url(${bgImageLarger});
    height: 780px;
    padding-top: 65px;
    padding-left: 153px;
    padding-right: 153px;
  `}
`

const LastArticle = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: column;

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`

const Section = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  width: 100%;
  height: 320px;

  ${media.greaterThan('medium')`
    width: 52%;
    height: unset;
  `}
`

const Aside = styled.div`
  width: 100%;
  background-color: #4472de;
  background-repeat: no-repeat;
  background-image: url(${bgContent});
  padding: 30px;
  padding-top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media.greaterThan('medium')`
    width: 48%;
  `}
`

const Title = styled.span`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  margin: 10px 0px;
  color: ${white};
  text-transform: capitalize;
`

const ArticleTitle = styled.span`
  font-weight: bold;
  font-size: 36.3969px;
  line-height: 46px;

  color: #f6f6f6;
`
const BriefDescription = styled.span`
  font-size: 14px;
  line-height: 24px;
  margin-top: 35px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: #f6f6f6;
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 110px;
  flex-direction: column;
`

const Text = styled.span`
  font-size: 30px;
  line-height: 24px;
  padding: 10px 0px;
  text-align: center;
  color: ${white};
`

const Img = styled.img`
  padding-top: 30px;
`

const Header = ({ title, text, image, avatar, author, minutesRead, empty, loading }) => {
  let content = (
    <LastArticle>
      <Section image={image} />
      <Aside>
        <ArticleTitle>{title}</ArticleTitle>
        <BriefDescription>{text}</BriefDescription>
        <ArticleInfo
          style={{ marginTop: 110 }}
          styleText={{ color: white }}
          avatar={avatar}
          author={author}
          minutesRead={minutesRead}
        />
      </Aside>
    </LastArticle>
  )

  if (loading || empty) {
    content = (
      <InfoContainer>
        <Text>{loading ? 'Loading...' : "We don't have any articles yet"}</Text>
        <Img src={search} alt="Loading" />
      </InfoContainer>
    )
  }

  return (
    <Container>
      <Content>
        <Title>Welcome to our Blog</Title>
        {content}
      </Content>
    </Container>
  )
}

Header.propTypes = {
  title: string,
  text: string,
  image: string,
  avatar: string,
  author: string,
  minutesRead: number,
  empty: bool,
  loading: bool
}

export default Header
