import React, { useState, useEffect } from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'

import ArticleContext from '@/context/ArticleContext'

import { white } from '@/style-guide/colors'

import Layout, { TopBar, Footer } from '@/components/Layout'
import SEO from '@/components/seo'
import ArticlePreview from '@/components/ArticlePreview'
import ArticleInfo from '@/components/ArticlePreview/ArticleInfo'

import arrowRight from '@/assets/icons/arrow-right.svg'

import Header from './Header'

const Container = styled.div`
  background-color: ${white};
  height: 100vh;
  width: 100%;
`

const Content = styled.div`
  background: #f7f7f7;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 40px;

  ${media.greaterThan('medium')`
    grid-template-columns: auto auto auto;
    padding-top: 60px;
    padding-left: 153px;
    padding-right: 153px;
  `}
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  padding-top: 20px;

  > * {
    margin-right: 10px;
  }
`

const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const Img = styled.img`
  cursor: pointer;
`

const ImgPrevious = styled.img`
  transform: rotate(180deg);
  cursor: pointer;
`

const Link = styled(LinkComponent)`
  align-items: center;
  display: flex;
  text-decoration: none;
`

const Blog = () => {
  const [articles, setArticles] = useState([])
  const [numPages, setNumPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pinned, setPinned] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`https://cms-calculatex.herokuapp.com/articles?_start=0&_limit=1&_sort=createdAt:DESC`)
      .then(response => response.json())
      .then(pinnedArticle => {
        setPinned(pinnedArticle[0])
      })

    fetch(
      `https://cms-calculatex.herokuapp.com/articles?_start=${1 +
        6 * (currentPage - 1)}&_limit=6&_sort=createdAt:DESC`
    )
      .then(response => response.json())
      .then(articlesList => {
        setArticles(articlesList)
        setLoading(false)
      })

    fetch(`https://cms-calculatex.herokuapp.com/articles?_start=1`)
      .then(response => response.json())
      .then(articlesList => {
        setNumPages(Math.ceil(articlesList.length / 6))
      })
  }, [currentPage])

  return (
    <ArticleContext.Consumer>
      {value => (
        <>
          <SEO title="Blog" />
          <Layout>
            <Container>
              <TopBar />
              <Link to="/article" onClick={() => value.handleArticleId(pinned._id)}>
                <Header
                  title={pinned?.title}
                  text={pinned?.text}
                  author={pinned?.author?.name}
                  avatar={pinned?.author?.avatar.url}
                  minutesRead={pinned?.minutesRead}
                  image={pinned?.image?.url}
                  loading={loading}
                  empty={!pinned}
                />
              </Link>
              {!!articles.length && (
                <>
                  <Content>
                    {articles.map(
                      (
                        { _id, title, text, minutesRead, image: { url: articleImage }, author },
                        index
                      ) => (
                        <Link key={index} to="/article" onClick={() => value.handleArticleId(_id)}>
                          <ArticlePreview
                            title={title}
                            text={text}
                            image={articleImage}
                            footer={
                              <ArticleInfo
                                author={author?.name}
                                avatar={author?.avatar.url}
                                minutesRead={minutesRead}
                              />
                            }
                          />
                        </Link>
                      )
                    )}
                  </Content>
                  <Pagination>
                    {currentPage !== 1 && (
                      <ImgPrevious
                        src={arrowRight}
                        alt="Previous page"
                        onClick={() => setCurrentPage(currentPage - 1)}
                      />
                    )}
                    <Text>
                      Page {currentPage} of {numPages}
                    </Text>
                    {currentPage !== numPages && (
                      <Img
                        src={arrowRight}
                        alt="Next page"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      />
                    )}
                  </Pagination>
                </>
              )}
              <Footer style={{ marginTop: 0, paddingTop: 50 }} />
            </Container>
          </Layout>
        </>
      )}
    </ArticleContext.Consumer>
  )
}

export default Blog
